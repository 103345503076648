<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    style="padding-top: 0;padding-bottom: 0">
    <v-layout
      justify-center
      wrap>
      <v-flex
        md12
        style="padding-top: 0;padding-bottom: 0">
        <v-card-title
          style="padding: 0">
          <v-flex
            xs12
            md9
            style="padding: 0">
            <v-btn
              class="mx-3  font-weight-medium"
              color="green"
              @click.prevent="showAddDialog">
              <v-icon>mdi-playlist-plus
              </v-icon>
              <span class="mx-2 font-weight-medium">创建项目</span>
            </v-btn>
          </v-flex>
          <v-flex
            xs12
            md3
            style="padding: 0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details/>
          </v-flex>
        </v-card-title>
        <material-card
          color="green"
          style="margin-top: 30px">
          <div
            slot="header"
            class="d-flex">
            <span>
              <div class="title mb-2">
                项目列表信息
              </div>
            </span>
            <span
              style="text-align:right;margin-top: -10px">
              <div>
                <span style="margin-right:20px;">
                  <v-btn
                    small
                    round
                    class="mx-0 font-weight-medium"
                    color="transparent"
                    style="margin-right:20px"
                    @click="projectList">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </span>
              </div>
            </span>
          </div>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :expand="expand"
            class="elevation-3 text-xs-left"
            hide-actions>
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="subheading font-weight-regular text-success text--darken-3 text-xs-left"
                v-text="header.text"/>
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td/>
                <td>{{ item.id }}</td>
                <td class="font-weight-regular">{{ item.projectName }}</td>
                <td>{{ item.syncUrl }}</td>
                <td>{{ getUsernames(item.adminUsers) }}</td>
                <td style="padding-top: 0;padding-bottom: 0">
                  <v-btn
                    slot="activator"
                    class="v-btn--small"
                    color="green"
                    icon
                    @click.stop="showBindUserDialog(item)">
                    <v-icon>mdi-account-plus-outline</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    slot="activator"
                    class="v-btn--small"
                    color="red"
                    icon
                    @click.prevent="showEditDialog(item)"
                  >
                    <v-icon>mdi-briefcase-edit-outline</v-icon>
                  </v-btn>
                </td>
                <!--                <td style="padding-top: 0;padding-bottom: 0">-->
                <!--                  <v-btn-->
                <!--                    slot="activator"-->
                <!--                    class="v-btn&#45;&#45;small"-->
                <!--                    color="red"-->
                <!--                    icon-->
                <!--                    @click.stop="showDeleteDialog(item)">-->
                <!--                    <v-icon>mdi-delete-forever</v-icon>-->
                <!--                  </v-btn>-->
                <!--                </td>-->
              </tr>
            </template>
          </v-data-table>
          <!--          <v-layout>-->
          <!--            <v-flex-->
          <!--              xs12-->
          <!--              md1>-->
          <!--              <v-select-->
          <!--                v-model="pagination.rowsPerPage"-->
          <!--                :items="selectedItems"-->
          <!--                label="每页"-->
          <!--                @change="selectChanged"/>-->
          <!--            </v-flex>-->
          <!--            <v-flex-->
          <!--              xs12-->
          <!--              md11-->
          <!--              class="text-xs-right">-->
          <!--              <div class=" pt-2 ">-->
          <!--                <v-pagination-->
          <!--                  v-model="pagination.page"-->
          <!--                  :length="pages"-->
          <!--                  sort-by=""/>-->
          <!--              </div>-->
          <!--            </v-flex>-->
          <!--          </v-layout>-->
        </material-card>
      </v-flex>
    </v-layout>

    <!--弹出创建项目填写框 S-->
    <v-dialog
      v-model="showHide.AddDialog"
      max-width="600"
      persistent
      @keydown.esc="showHide.AddDialog = false">
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green">
            <div
              slot="header">
              <div class="mb-2 d-flex">
                <span class="title mb-2">创建项目</span>
                <span class="text-xs-right">
                  <v-icon
                    size="24"
                    @click="closeAddDialog">
                    mdi-close-circle
                  </v-icon>
                </span>
              </div>
            </div>
            <v-flex
              xs12
              md12>
              <v-flex
                xs12
                md12>
                <v-text-field
                  v-model="addDialog.projectName"
                  label="项目名称"
                  style="margin-top:20px"
                  required
                  @keyup.enter="createProject"/>
                <v-text-field
                  v-model="addDialog.syncUrl"
                  label="同步地址"
                  required
                  @keyup.enter="createProject"/>
              </v-flex>
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px">
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="createProject">
              确认创建
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出创建项目填写框 E-->

    <!--弹出项目编辑框 S-->
    <v-dialog
      v-model="showHide.EditDialog"
      max-width="600"
      persistent
      @keydown.esc="showHide.EditDialog = false">
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green">
            <div
              slot="header">
              <div class="mb-2 d-flex">
                <span class="title mb-2">编辑项目</span>
                <span class="text-xs-right">
                  <v-icon
                    size="24"
                    @click="closeEditDialog">
                    mdi-close-circle
                  </v-icon>
                </span>
              </div>
            </div>
            <v-flex
              xs12
              md12>
              <v-text-field
                v-model="editDialog.projectName"
                label="项目名称"/>
            </v-flex>
            <v-flex
              xs12
              md12>
              <v-text-field
                v-model="editDialog.syncUrl"
                label="同步地址"
                required
                @keyup.enter="modifyProject"/>
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px">
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="modifyProject">
              确认编辑
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出项目编辑框 E-->

    <!--弹出绑定用户编辑框 S-->
    <v-dialog
      v-model="showHide.BindDialog"
      max-width="600"
      persistent
      @keydown.esc="showHide.BindDialog = false">
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green">
            <div
              slot="header">
              <div class="mb-2 d-flex">
                <span class="title mb-2">绑定用户</span>
                <span class="text-xs-right">
                  <v-icon
                    size="24"
                    @click="closeBindUserDialog">
                    mdi-close-circle
                  </v-icon>
                </span>
              </div>
            </div>
            <v-flex
              xs12
              md12>
              <v-select
                v-model="bindDialog.selected"
                :items="bindDialog.all"
                :menu-props="{ maxHeight: '400' }"
                label="用户"
                multiple
                chips
                hint="选择用户"
                persistent-hint
              />
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px">
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="bindUsers">
              确认绑定
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出绑定用户编辑框 E-->

    <!--弹出的删除确认框 S-->
    <v-dialog
      v-model="showHide.DeleteDialog"
      max-width="600"
      persistent
      @keydown.esc="showHide.DeleteDialog = false">
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green">
            <div
              slot="header">
              <div class="mb-2 d-flex">
                <span class="title mb-2">设备名称验证</span>
                <span class="text-xs-right">
                  <v-icon
                    size="24"
                    @click="closeDeleteDialog">
                    mdi-close-circle
                  </v-icon>
                </span>
              </div>
              <div>
                <p class="category font-weight-medium">输入设备名称后确认删除：<span class="title text-warning font-weight-bold">{{ deleteDialog.confirmdevicename }}</span>
                </p>
              </div>
            </div>
            <v-flex
              xs12
              md12>
              <v-text-field
                v-focus
                id="delinput"
                ref="delinput"
                v-model="deleteDialog.devicename"
                label="设备名称"
                required
                @keyup.enter="deleteProject"/>
              <div style="display: none">
                <v-text-field
                  v-model="deleteDialog.confirmdevicename"
                  label="设备名称"/>
              </div>
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px">
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="deleteProject">
              确认删除
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出的删除确认框 E-->

  </v-container>
</template>

<script>
export default {
  directives: {
    focus (el) {
      let dialogusername = document.getElementById('dialogusername')
      if (dialogusername !== undefined && dialogusername != null) {
        dialogusername.focus()
      }
    }
  },
  data: () => ({
    search: '',
    showHide: {
      AddDialog: false,
      DeleteDialog: false,
      EditDialog: false,
      BindDialog: false
    },
    addDialog: {
      projectName: '',
      syncUrl: ''
    },
    editDialog: {
      projectName: '',
      syncUrl: '',
      id: ''
    },
    bindDialog: {
      selected: [],
      all: [],
      members: [],
      id: ''
    },
    deleteDialog: {
      projectName: '',
      confirm: '',
      id: ''
    },
    // pagination: { page: 3, rowsPerPage: 5, totalItems: '', sortBy: '' },
    expand: true,
    selectedItems: ['5', '10', '20', '50', '100'],
    headers: [
      { sortable: false, text: '', value: '', width: '1%' },
      { sortable: false, text: '#', value: 'id', width: '5%' },
      { sortable: false, text: '项目名称', value: 'projectName', width: '25%' },
      { sortable: false, text: '同步地址', value: 'syncUrl', width: '25%' },
      { sortable: false, text: '用户', value: 'adminUsers' },
      { sortable: false, text: '', value: '', width: '5%' },
      { sortable: false, text: '编辑', value: '' }
    ],
    items: [],
    members: []
  }),
  computed: {
    // pages () {
    //   if (this.pagination.rowsPerPage == null ||
    //     this.pagination.totalItems == null
    //   ) return 0
    //
    //   return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    // }
  },
  created () {
    this.projectList()
    this.memberList()
  },
  methods: {
    // selectChanged () {
    //   let maxPage = Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    //   if (this.pagination.page > maxPage) {
    //     this.pagination.page = maxPage
    //   }
    //   this.$localStorage.set(this.$session.get('signIn').username + '_device_rowsPerPage', this.pagination.rowsPerPage)
    // },
    getUsernames (adminUsers) {
      // let result = ''
      let arr = []
      // console.log(adminUsers)
      for (let i = 0; i < adminUsers.length; i++) {
        let user = adminUsers[i]
        // result = result + user.username + ', '
        // console.log(user)
        arr.push(user.username)
      }
      return arr
    },
    projectList () {
      this.items = []
      let config = this.$authHeaders()
      config.params = {
        size: 100,
        page: 0
      }
      this.$http
        .get(this.$store.state.app.url + '/project/projectList', config)
        .then(res => {
          let data = res.data
          if (data.errCode === 0) {
            let result = data.result
            console.log(result)
            this.items = result.content
          } else {
            console.log(data.errMsg)
            this.$snackbar.error(data.errMsg)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    memberList () {
      this.members = []
      let config = this.$authHeaders()
      config.params =
        config.params = {
          size: 100,
          page: 0
        }
      this.$http
        .get(this.$store.state.app.url + '/admin/ctrl/userList', config)
        .then(res => {
          let data = res.data
          if (data.errCode === 0) {
            let result = data.result
            this.members = result.content
            console.log(result)
          } else {
            console.log(data.errMsg)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    showAddDialog () {
      this.showHide.AddDialog = true
      this.addDialog.projectName = ''
      this.addDialog.syncUrl = ''
    },
    closeAddDialog () {
      this.showHide.AddDialog = false
    },
    createProject () {
      if (this.addDialog.projectName === '') {
        this.$snackbar.info('请输入项目名称')
        return
      }
      if (this.addDialog.syncUrl === '') {
        this.$snackbar.info('请输入同步地址')
        return
      }
      let data = {
        projectName: this.addDialog.projectName,
        syncUrl: this.addDialog.syncUrl
      }
      this.$http
        .post(this.$store.state.app.url + '/project/addProject', data, this.$authHeaders())
        .then(res => {
          let data = res.data
          if (data.errCode === 0) {
            let result = data.result
            console.log(result)
            this.projectList()
            this.$snackbar.success('创建成功')
            this.closeAddDialog()
          } else {
            console.log(data.errMsg)
            this.$snackbar.error(data.errMsg)
          }
        }, (error) => {
          this.$httpCatch(error)
        })
    },
    showBindUserDialog (item) {
      console.log('showBindUserDialog()', item)
      this.showHide.BindDialog = true
      this.bindDialog.selected = this.getUsernames(item.adminUsers)
      this.bindDialog.all = this.getUsernames(this.members)
      this.bindDialog.members = this.members
      this.bindDialog.id = item.id
    },
    closeBindUserDialog () {
      console.log('closeBindUserDialog()')
      this.showHide.BindDialog = false
    },
    bindUsers () {
      let data = {
        projectId: this.bindDialog.id,
        usernames: this.bindDialog.selected
      }
      this.$http.post(this.$store.state.app.url + '/project/updateBindUsers', data, this.$authHeaders())
        .then(res => {
          let data = res.data
          if (data.errCode === 0) {
            let result = data.result
            console.log(result)
            this.projectList()
            this.$snackbar.success('绑定完成')
            this.closeBindUserDialog()
          } else {
            console.log(data.errMsg)
            this.$snackbar.error(data.errMsg)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    showEditDialog (item) {
      console.log('showEditDialog()', item)
      this.showHide.EditDialog = true
      this.editDialog.id = item.id
      this.editDialog.projectName = item.projectName
      this.editDialog.syncUrl = item.syncUrl
    },
    closeEditDialog () {
      this.showHide.EditDialog = false
    },
    modifyProject () {
      if (this.editDialog.projectName === '') {
        this.$snackbar.info('请输入项目名称')
        return
      }
      if (this.editDialog.syncUrl === '') {
        this.$snackbar.info('请输入同步地址')
        return
      }
      let data = {
        id: this.editDialog.id,
        projectName: this.editDialog.projectName,
        syncUrl: this.editDialog.syncUrl
      }
      this.$http.post(this.$store.state.app.url + '/project/modifyProject', data, this.$authHeaders())
        .then(res => {
          let data = res.data
          if (data.errCode === 0) {
            let result = data.result
            console.log(result)
            this.projectList()
            this.$snackbar.success('编辑完成')
            this.closeEditDialog()
          } else {
            console.log(data.errMsg)
            this.$snackbar.error(data.errMsg)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    showDeleteDialog (item) {
      // this.showHide.DeleteDialog = true
      // this.deleteDialog.devicename = ''
      // this.deleteDialog.confirmdevicename = rowsprops.item.devicename
      // this.deleteDialog.deviceId = rowsprops.item.id
    },
    closeDeleteDialog () {
      this.showHide.DeleteDialog = false
    },
    deleteProject () {
      if (this.deleteDialog.confirmdevicename !== this.deleteDialog.devicename) {
        this.$snackbar.warning('输入不正确，请重新输入')
        return
      }
      let formdata = new FormData()
      formdata.append('id', this.deleteDialog.deviceId)
      this.$http.post(this.$store.state.app.url + '/mediaSystem/device/deleteProject', formdata, this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            for (let i = 0; i < this.items.length; i++) {
              if (this.items[i].devicename === this.deleteDialog.devicename) {
                this.items.splice(i, 1)
                this.closeDeleteDialog()
              }
            }
            this.projectList()
            this.$snackbar.success(res.message)
          } else {
            this.$snackbar.error(res.message)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    }
  }
}
</script>

<style scoped>
  #inputHidden {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  #app {
    opacity: 1;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 50%;

    /*解决出现横向滚动条问题*/
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .v-enter {
    opacity: 0;
    transform: translateY(100%);
    position: absolute;
  }

  .v-leave-to {
    transform: translateY(100%);
  }

  .v-enter-active, .v-leave-active {
    transition: all 0.4s ease
  }
</style>
